const mulish = {
  fontFamily: 'Mulish',
  fontWeight: 400,
}
const mulishBold = {
  fontFamily: 'Mulish',
  fontWeight: 600,
}

const fontsArray = [mulish, mulishBold]

export default fontsArray
