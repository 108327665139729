import React from 'react'
import { Nav, WrapperNavigation } from './NavigationBar.style'
import NavigationMenuDesktop from './components/NavigationMenuDesktop'
import NavigationIcon from './components/NavigationIcon'
import NavigationLogo from './components/NavigationLogo'
import { useSelector } from 'react-redux'
import { openDrawerSearchSelector } from '@features/ui/selector'

export interface NavigationBarProps {
  hasScrolled: boolean
}

const NavigationBar: React.FC<NavigationBarProps> = ({ hasScrolled }: NavigationBarProps) => {
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)

  return (
    <WrapperNavigation>
      <Nav>
        <NavigationLogo />
        {!isSearchDrawerOpen && <NavigationMenuDesktop hasScrolled={hasScrolled} />}
        <NavigationIcon />
      </Nav>
    </WrapperNavigation>
  )
}

export default NavigationBar
