import styled from '@mui/material/styles/styled'

export const WrapperNavigation = styled('nav')(({ theme }) => ({
  height: 64,
  display: 'flex',
  boxShadow: `inset 0 -1px 0 0 ${theme.palette.background.light.tertiary}`,
  fontSize: 12,
  backgroundColor: theme.palette.background.light.primary,
}))

export const Nav = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(0)} ${theme.spacing(4)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(0)} ${theme.spacing(16)}`,
  },
}))
