import { Chip } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const NavIcon = styled('div', {
  name: 'NavigationIcon',
  slot: 'NavIcon',
})(({ theme }) => ({
  display: 'inherit',
  gap: theme.spacing(4),
}))

export const ContentIconButton = styled('div', {
  name: 'NavigationIcon',
  slot: 'IconButton',
})({
  display: 'inherit',
})

export const NavIconWrapper = styled('div', {
  name: 'CartNav',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: theme.spacing(1),
}))

export const NavTinyChip = styled(Chip, {
  name: 'CartNav',
  slot: 'Chip',
})(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(4),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  background: theme.palette.custom.light.blue,
  fontSize: theme.typography.subtitle2.fontSize,
  cursor: 'pointer',

  '.MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0
  }
}))
