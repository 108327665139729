import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV3 } from '../../../../../../constants/ui'
import { StyledAnchor } from '../../../../../../components/UI'
import fontsArray from '../../../../../../themes/Clearly/fonts'

export const MegaMenuWrapper = styled('div', {
  name: 'NavigationMenuDesktop',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'none',
  height: '100%',
  gap: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    display: 'inherit',
  },
}))

export const MegaMenuItemButton = styled('span', {
  name: 'NavigationMenuDesktop',
  slot: 'ItemButton',
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isHighlighted',
})<{ active: boolean; isHighlighted: boolean }>(({ active, theme, isHighlighted }) => ({
  fontWeight: fontsArray[1].fontWeight,
  fontSize: theme.typography.body2.fontSize,
  borderBottom: '4px solid',
  borderBottomColor: active ? theme.palette.custom.blue : 'transparent',
  borderTop: '4px solid transparent',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderBottomColor: theme.palette.custom.blue,
  },

  'span:first-of-type': {
    padding: isHighlighted && `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    ...(isHighlighted && { color: theme.palette.custom.red }),
  },
}))

export const MegaMenuItemLink = styled(StyledAnchor, {
  name: 'NavigationMenuDesktop',
  slot: 'ItemLink',
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isHighlighted',
})<{ active: boolean; isHighlighted: boolean }>(({ active, theme, isHighlighted }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.custom.black,
  fontWeight: fontsArray[1].fontWeight,
  fontSize: theme.typography.body2.fontSize,
  borderBottom: '4px solid',
  borderBottomColor: active ? theme.palette.custom.blue : 'transparent',
  borderTop: '4px solid transparent',

  '&:hover': {
    borderBottomColor: theme.palette.custom.blue,
  },

  '&:visited': {
    color: theme.palette.custom.black,
  },

  '&:after': {
    content: 'initial',
  },
  'span:first-of-type': {
    padding: isHighlighted && `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    ...(isHighlighted && { color: theme.palette.custom.red }),
  },
}))

export const MegaMenuDrawer = styled('div', {
  name: 'NavigationMenuDesktop',
  slot: 'Drawer',
  shouldForwardProp: prop => prop !== 'open' && prop !== 'hasScrolled',
})<{ hasScrolled: boolean; open: boolean }>(({ hasScrolled, open, theme }) => ({
  position: hasScrolled ? 'fixed' : 'absolute',
  background: theme.palette.background.light.primary,
  top: 104,
  left: 0,
  right: 0,
  zIndex: Z_INDEX_LV3,
  height: open ? 'calc(100vh - 96px)' : 0,
  visibility: open ? 'visible' : 'hidden',
  transition: 'all 0.3s ease 0s',
}))
